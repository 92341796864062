import React, {useContext, useState, useEffect} from "react";
import queryString from "query-string";
import {CartContext} from '../cart';


const Cart = () => {
    const isStage = document.location.host !== 'cooperation.coffee';
    const query =
        typeof window !== 'undefined' ?
        queryString.parse(window.location.search) : {};

    const {checkoutId} = useContext(CartContext);
    
    useEffect(() => {
      if (checkoutId) {
        fetch(
          `https://workers.cooperation.coffee/order-info?checkout=${checkoutId}&order=${query.order}${isStage ? '&stage=1' : ''}`
        ).then(async (resp) => {
          const payload = await resp.json();
          var form = document.createElement('form');
          form.style.visibility = 'hidden';
          form.method = 'POST';
          form.action = isStage ? 
            'https://payment-stage.ecpay.com.tw/Cashier/AioCheckOut/V5' :
            'https://payment.ecpay.com.tw/Cashier/AioCheckOut/V5'; 
          const body = new FormData();
          for (const key in payload) {
            var input = document.createElement('input');
            input.name = key;
            input.value = payload[key];
            form.appendChild(input)
          }
          document.body.appendChild(form);
          form.submit();
        });
      }
    }, [checkoutId]);


    return (
        <div>
            <div
                style={{
                    display: "flex",
                    maxWidth: 800,
                    margin: '96px auto',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <div>
                  訂單交易處理中
                </div>
                <div>
                  請勿關閉分頁 ...
                </div>
            </div>
        </div>
    );
};

export default Cart;
